import React from 'react';

export const SvgGetTheAppIcon = () => (
  <svg
    id="get-the-app-icon_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 109.975 109.972"
    width="100%"
    height="100%"
  >
    <defs>
      <style>
        {
          '.get-the-app-icon_svg__cls-2{fill:#fff}.get-the-app-icon_svg__cls-3{fill:#b7bcbd}'
        }
      </style>
    </defs>
    <circle
      id="project-icon__background"
      cx={54.987}
      cy={54.986}
      r={54.987}
      fill="#c2e7f2"
    />
    <g id="get-the-app-icon_svg__Foreground">
      <g id="get-the-app-icon_svg__IPhone">
        <path
          className="get-the-app-icon_svg__cls-2"
          d="M72.424 80.843a6.017 6.017 0 01-5.896 6.028l-23.434.15a5.834 5.834 0 01-5.82-5.951l.328-51.913a6.017 6.017 0 015.896-6.028l23.433-.15a5.834 5.834 0 015.82 5.951z"
          transform="translate(-.025)"
        />
        <ellipse
          className="get-the-app-icon_svg__cls-3"
          cx={48.336}
          cy={30.086}
          rx={1}
          ry={0.975}
          transform="rotate(-75.063 48.323 30.103)"
        />
        <path
          className="get-the-app-icon_svg__cls-3"
          d="M62.981 29.992A1.003 1.003 0 0162 30.996l-9.764.063a.973.973 0 01-.97-.992 1.003 1.003 0 01.983-1.004L62.01 29a.973.973 0 01.97.992z"
          transform="translate(-.025)"
        />
        <ellipse
          cx={54.849}
          cy={80.956}
          rx={4}
          ry={3.899}
          transform="rotate(-75.06 54.84 80.974)"
          fill="#d0d3d4"
        />
        <path
          className="get-the-app-icon_svg__cls-2"
          d="M52.883 78.972l3.906-.025-.025 3.993-3.906.026.025-3.994z"
        />
        <ellipse
          className="get-the-app-icon_svg__cls-2"
          cx={61.413}
          cy={46.473}
          rx={2.9}
          ry={2.827}
          transform="rotate(-75.063 61.4 46.49)"
        />
        <path fill="#f1f1f1" d="M39.242 34.933h31.251v39.95H39.242z" />
      </g>
      <g id="get-the-app-icon_svg__grubhub">
        <rect
          x={43.779}
          y={43.791}
          width={22.417}
          height={22.417}
          rx={7.461}
          fill="#ef3b43"
        />
        <path
          className="get-the-app-icon_svg__cls-2"
          d="M52.875 53.824c0 .28-.12 1.398-.92 1.638-.599.16-.878-.28-.878-1.319v-3.756a2.54 2.54 0 01.32-1.319 1.403 1.403 0 01.839-.48c.32 0 .64.28.64.88v.559c0 .12.12.24.24.16l1.398-.56c.04 0 .12-.04.12-.16v-.639a2.154 2.154 0 00-2.797-2.078 3.529 3.529 0 00-2.638 3.477v4.635a2.327 2.327 0 002.197 2.518 3.365 3.365 0 003.237-3.676v-2.877c0-.16-.12-.16-.24-.16l-2.278.799a.205.205 0 00-.16.24v1.319c0 .12.12.16.24.16l.64-.24v.879zm7.712 8.75c.12.04.24 0 .24-.16v-9.71c0-.12-.04-.16-.16-.16l-1.478-.559c-.12-.04-.24 0-.24.16v3.836l-1.479-.559v-3.876c0-.12-.04-.16-.16-.16l-1.478-.56c-.12-.04-.24 0-.24.16v9.79c0 .12.04.16.16.16l1.478.56c.12.04.24 0 .24-.16V57.3l1.478.56v4.035c0 .12.04.16.16.16l1.48.52z"
          transform="translate(-.025)"
        />
      </g>
    </g>
    <path
      d="M47.237 60.336a13.527 13.527 0 11-16.12-10.303 13.527 13.527 0 0116.12 10.303zm-18.935 2.267l-3.523.776.626 2.842 1.957-.431zm12.907.138l1.286-.283-.625-2.841-2.994.659 1.78 1.881zm-8.865-1.028l-.904 3.179 5.258-1.158-2.197-2.495-2.157.474zm-4.637 8.693a1.78 1.78 0 01-.713-.446l.268 1.81 1.284-1.303a1.665 1.665 0 01-.839-.061zm3.85-11.822c-.619-.202-.837.02-.878.071l-.003-.001-.004.011-.267.818-.004.012.004.001c.003.065.048.373.667.575.662.216.847-.017.88-.07h.001l.004-.011.267-.818.004-.012h-.002c.005-.063-.007-.36-.669-.576zm-.606 1.857c-.695-.226-.675-.562-.675-.562l-.127.388-.252.773-.017.054s-.028.36.667.587c.743.243.885-.08.885-.08l.017-.055.252-.772.127-.388s-.133.298-.877.055zm-.401 1.674a2.55 2.55 0 01-.132-.039c-.696-.227-.675-.562-.675-.562l-.274.84-.04.123-.065.198-.926 2.837-.95 2.912-.25.764-.02.063s-.028.36.667.587c.744.243.885-.08.885-.08l.02-.064.25-.763 1.248-3.826.628-1.924.064-.197.315-.963s-.118.261-.745.094zm9.393.97l-.046-.047-.006-.007-.55-.573-1.774-1.846-3.607-3.756s-2.538-2.642-3.127-2.076 1.95 3.207 1.95 3.207l2.916 3.036 2.324 2.42.14.146.557.58.046.047s.304.195.832-.312c.563-.542.345-.818.345-.818zm1.359 1.419l.002-.002-.008-.007-.602-.628-.236-.245s.2.258-.364.8c-.527.507-.813.331-.813.331l.235.246.603.627.007.007.003-.002c.056.028.35.136.822-.318.505-.485.382-.757.35-.809zm.57.59a1.06 1.06 0 01-.412.749 1.042 1.042 0 01-.765.382s1.388 3.201 2.854 1.793c1.455-1.397-1.677-2.924-1.677-2.924z"
      transform="translate(-.025)"
      fill="#42beee"
      id="get-the-app-icon_svg__apple-appstore"
    />
    <g id="get-the-app-icon_svg__google-play">
      <path
        d="M90.354 36.006a2.132 2.132 0 01-.61 3.173l-2.948.93-3.247-3.898 4.55-2.18z"
        transform="translate(-.025)"
        fill="#ffc907"
      />
      <path
        d="M86.82 40.1l-3.274-3.888-.001-.001v.002-.002l-.002-.002-.016.007-16.783 7.931-.097.46a1.585 1.585 0 00.312 1.614c.576.432 1.632.05 1.632.05z"
        transform="translate(-.025)"
        fill="#ef3c4a"
      />
      <path
        fill="#43c7f1"
        d="M83.501 36.216l-16.783 7.931 4.729-22.248 12.051 14.312.003.005z"
      />
      <path
        d="M71.52 21.676l-.047.223 12.05 14.312.003.005.016-.007h.002l.006-.004 4.545-2.157s-10.975-10.19-14.16-13.132a1.207 1.207 0 00-.954-.396 1.81 1.81 0 00-1.461 1.156z"
        transform="translate(-.025)"
        fill="#65be6b"
      />
    </g>
  </svg>
);
