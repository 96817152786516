import React from 'react';

export const SvgGatsbyIcon = () => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 109.975 109.972"
    width="100%"
    height="100%"
  >
    <circle
      id="project-icon__background"
      cx={54.987}
      cy={54.986}
      r={54.987}
      fill="#e4dce9"
    />
    <path
      id="gatsby-icon_svg__logo"
      d="M54.987 25.518a29.467 29.467 0 1029.468 29.468 29.467 29.467 0 00-29.468-29.467zM31.852 55.293L54.68 78.121a23.137 23.137 0 01-22.828-22.828zm28.314 22.25L32.43 49.807a23.143 23.143 0 0141.265-8.44l-3.204 2.828a18.894 18.894 0 00-33.27 4.36L61.42 72.75A18.929 18.929 0 0073.33 59.52h-10.03v-4.533h14.828a23.148 23.148 0 01-17.96 22.557z"
      fill="#754d9f"
    />
  </svg>
);
